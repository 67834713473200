import { ApolloClient, InMemoryCache } from '@apollo/client';
import {createUploadLink} from 'apollo-upload-client';
import { from, HttpLink, ApolloLink } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from "@apollo/client/link/error";

import { setContext } from "@apollo/client/link/context";
import User from 'store/user';

const ErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) console.log('graphQLErrors', graphQLErrors);
  if (networkError) console.log('networkError', networkError);

  if(networkError?.statusCode === 401 && window.logout) {
    window.logout();
  }
  // if(networkError?.statusCode === 401 && !window.logout) {
  //   localStorage.removeItem("user");
  //   window.location.href = '/login';
  // }

});

const uploadLink = createUploadLink({ 
  uri: process.env.REACT_APP_API 
});

const AuthLink = setContext(async (_, { headers }) => {

  let data = localStorage.getItem("user");
  let token, refreshToken;
  if(data) {
    data = JSON.parse(data);
    token = data.token;
    refreshToken = data.refreshToken;
  }

  const businessId = await localStorage.getItem("businessId");

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'x-openyou-business-id': businessId ? businessId : '',
      'x-refresh-token': refreshToken ? refreshToken : '',
    }
  }
});

const AfterwareLink = new ApolloLink((operation, forward) =>
  forward(operation).map(response => {
    const res = operation.getContext().response;
    if (res) {
      const { headers } = res;
      if (headers) {
        const accessToken = headers.get('x-access-token');


        if (accessToken) {
          let data = localStorage.getItem("user");
          if(data) {
            console.log("renewing");
            data = JSON.parse(data);
            data.token = accessToken;
            User.data = data;
            localStorage.setItem("user", JSON.stringify(data));
          }
        }
      }
    }
    return response;
  }),
);




const additiveLink = from([
  ErrorLink,
  AuthLink,
  AfterwareLink,
  uploadLink
]);

const client = new ApolloClient({
  link: additiveLink,
  cache: new InMemoryCache({
  	addTypename: false
  })
});

export default client;