import { Menu, Button } from "antd";
import {
	AppstoreOutlined,
	BlockOutlined,
	SolutionOutlined,
	TeamOutlined,
	FileTextOutlined,
	NotificationOutlined,
} from "@ant-design/icons";
import CertificatePurchase from "components/CertificatePurchase";
const MenuRoutes = ({ handleClick, keys, userInfo }) => {
	return (
		<>
			<Menu
				onClick={handleClick}
				style={{ width: 280 }}
				defaultSelectedKeys={keys}
				selectedKeys={keys}
				openKeys={["expenses"]}
				mode="inline"
				expandIcon={<span></span>}
			>
				<Menu.Item icon={<AppstoreOutlined />} key={"/"}>
					Dashboard
				</Menu.Item>

				<Menu.Item icon={<TeamOutlined />} key={"/admins"}>
					Administradores
				</Menu.Item>

				<Menu.Item icon={<SolutionOutlined />} key={"/users"}>
					PJ
				</Menu.Item>

				<Menu.Item icon={<BlockOutlined />} key={"/processes"}>
					Processos
				</Menu.Item>

				<Menu.Item icon={<NotificationOutlined />} key={"/refer"}>
					Clube UAU OpenYOU
				</Menu.Item>
			</Menu>
			{/* <CertificatePurchase/> */}
		</>
	);
};

export default MenuRoutes;
