import React, { useEffect } from "react";
import Loading from "components/Loading/Page";
import Client from "functions/apollo";
import User from "store/user";
import jwt from "jsonwebtoken";

import { gql } from "@apollo/client";

function AppWrapper({ children, client }) {
	// const [loading, setLoading] = React.useState(false);
	// if (loading) return <Loading />;
	return children;
}

export default AppWrapper;
