import React, { useEffect, useState } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

import User from "store/user";
import { Result } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useApolloClient, gql } from "@apollo/client";

function PrivateRoute({ component: Component }) {
	const client = useApolloClient();

	const [state, setState] = useState({
		status: "loading",
		path: null,
	});

	const location = useLocation();

	const getUserType = async () => {
		return new Promise(async (resolve, reject) => {
			let data = await localStorage.getItem("user");
			data = JSON.parse(data);

			if (data) {
				client
					.query({
						query: gql`
                    query GetUserType {
                      GetUserType {
                        type
                        subtype
                      }
                    }
                  `,
						fetchPolicy: "no-cache",
					})
					.then(({ data }) => {
						User.info = data.GetUserType;
						resolve(true);
					})
					.catch((e) => {
						console.log(e);
						reject(e);
					});
			} else {
				reject(false);
			}
		});
	};

	const getUserCheck = async () => {
		let data = await localStorage.getItem("user");
		data = JSON.parse(data);

		if (!data || !data.refreshToken || !data.token) {
			setState({ path: "/login", status: "redirect" });
		} else {
			User.check(client).then(
				(result) => {
					if (result?.pending) {
						if (location.pathname !== "/user-info") {
							setState({ path: "/user-info", logged: true });
						} else {
							setState({ logged: true });
						}
					} else {
						setState({ logged: true });
					}
				},
				(e) => {
					console.log(e);
					if (e === "server_error") {
						setState({ serverError: true });
					} else {
						setState({ path: "/login", status: "redirect" });
					}
				}
			);
		}
	};

	useEffect(() => {
		async function run() {
			try {
				await getUserType();
				await getUserCheck();
			} catch (error) {
				console.log(error);
				setState({ path: "/login", status: "redirect" });
			}
		}
		run();
	}, []);

	return (
		<Route
			render={(props) => {
				if (state.status === "server_error") {
					return (
						<div>
							<Result
								status="500"
								title="Ops"
								subTitle="Algo deu erro. Por favor, tente novamente mais tarde."
							/>
						</div>
					);
				}

				if (state.status === "redirect") {
					return (
						<Redirect
							to={{
								pathname: state.path,
								state: { from: props.location },
							}}
						/>
					);
				}

				if (state.status === "loading") {
					return (
						<div className="loading-view">
							<div className="spinner-wrapper">
								<LoadingOutlined />
							</div>
						</div>
					);
				}

				return <Component {...props} />;
			}}
		/>
	);
}

export default PrivateRoute;
