import React, { useState, useEffect } from "react";
import Loading from "components/Loading/Page";
import { useApolloClient, gql } from "@apollo/client";

import { Result, Button } from "antd";
import User from "store/user";

function AccessHandler({ children, path: route }) {

  const client = useApolloClient();

  const [state, setState] = useState({
    status: "loading"
  });

  useEffect(() => {

      client
      .query({
        query: gql`
          query AccessCheck($route: String!) {
            AccessCheck(route: $route) {
              result
              code
            }
          }
        `,
        fetchPolicy: "no-cache",
        variables: {
          route
        },
      })
      .then(({data}) => {

        if(data?.AccessCheck?.result) {
          setState({status: "success"});
        } else {
          setState({status: "error"});
          if(data?.AccessCheck?.code === "001") {
            window.logout();
          }
        }
      })
      .catch((e) => {
        setState({ status: "error" });
      });
  }, []);


  return (
    <div>

      {
        state.status === "loading" ? (
          <Loading />
        ) : null
      }

      {state.status === "success" ? children : null}

      {
        state.status === "error" ? (
          <Result
            status="403"
            title="Acesso negado"
            subTitle="Você não tem acesso à esta página."
          />
        ) : null
      }
      
    </div>
  );
}

export default AccessHandler;
