import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import User from "store/user";
import * as Menu from "./Menus";

const { Sider } = Layout;

function View(props) {
	const [keys, setKeys] = useState(["/"]);

	const handleClick = (e) => {
		props.history.push(e.key);
	};

	const setActiveRoute = () => {
		const { pathname } = props.history.location;
		if (pathname.indexOf("/user") > -1) {
			setKeys(["/users"]);
		} else {
			setKeys([pathname]);
		}
	};

	useEffect(() => {
		props.history.listen(() => {
			setActiveRoute();
		});
		const { pathname } = props.history.location;
		if (pathname.indexOf("/user") > -1) {
			setKeys(["/users"]);
		} else {
			setKeys([pathname]);
		}
	}, []);

	const notAllowedBillingSubtypes = ["management"];

	const renderMenu = () => {
		if (User.info.type === 1) {
			return (
				<Menu.User userInfo={User.info} handleClick={handleClick} keys={keys} />
			);
		}

		if (User.info.type === 2 || User.info.type === 3) {
			return (
				<Menu.Partner
					userInfo={User.info}
					handleClick={handleClick}
					keys={keys}
				/>
			);
		}

		return null;
	};

	let logo = require("assets/bases/openmei/logo.png").default;
	if (process.env.REACT_APP_THEME && process.env.REACT_APP_THEME !== "") {
		logo =
			require(`assets/bases/${process.env.REACT_APP_THEME}/logo.png`).default;
	}

	return (
		<Sider
			width={280}
			className={"sider"}
			breakpoint="lg"
			collapsedWidth="0"
			zeroWidthTriggerStyle={{
				color: "#95c050",
			}}
		>
			<div className="logo-wrapper">
				<img src={logo} alt="" />
			</div>

			{renderMenu()}
		</Sider>
	);
}

export default View;
