import React, { useState } from 'react';
import { Form, Icon, Input, Button, Checkbox } from "antd";

//import "assets/scss/login.scss";


import { useMutation } from '@apollo/client';

import * as Queries from './Queries';
import Swal from 'sweetalert2';
import isEmail from 'validator/lib/isEmail';

import User from 'store/user';

function Login({ history }) {

	const [state, setState] = useState({});
	const [loading, setLoading] = useState(false);

	const [resetAction] = useMutation(Queries.RESET_PASSWORD);


	const login = async () => {

		try {

			if (!state.email || !isEmail(state.email)) return Swal.fire({
				title: "Erro",
				text: 'Por favor, insira um e-mail válido.',
				type: "error",
				confirmButtonText: "OK"
			});

			setLoading(true);

			const res = await resetAction({
				variables: {
					email: state.email
				}
			});

			setLoading(false);

			if (res?.data?.ResetUserPassword) {
				setLoading(false);
				Swal.fire({
					title: "Sucesso",
					text: 'Enviamos um e-mail com um link para você redefinir sua senha.',
					type: "success",
					confirmButtonText: "OK"
				}).then(result => {
					if (result.isConfirmed) {
						history.push("/login");
					}
				})
			} else {
				setLoading(false);
				Swal.fire({
					title: "Erro",
					text: 'Não foi possível redefinir sua senha no momento. Por favor, tente novamente mais tarde.',
					type: "error",
					confirmButtonText: "OK"
				});
			}
		} catch (e) {
			setLoading(false);
			console.log(e);
			return Swal.fire({
				title: "Erro",
				text: 'Não foi possível fazer login no momento. Por favor, tent novamente mais tarde.',
				type: "error",
				confirmButtonText: "OK"
			});
		}

	}

	let logo = require('assets/bases/openmei/logo.png').default;
	if (process.env.REACT_APP_THEME && process.env.REACT_APP_THEME !== '') {
		logo = require(`assets/bases/${process.env.REACT_APP_THEME}/logo.png`).default;
	}

	return (
		<div className="login">
			<div className="login-wrapper">
				<div className="login-inset">
					<div className="logo-wrapper">
						<img
							src={logo}
							alt=""
						/>
					</div>

					<p>Para redefinir sua senha, por favor, informe o e-mail de cadastro.</p>

					<Form
						onSubmit={null}
						layout={"vertical"}
						className="login-form"
					>
						<Form.Item>
							<Input
								rules={[{ required: true }]}
								prefix={
									<Icon
										type="mail"
										style={{
											color: "rgba(0,0,0,.25)"
										}}
									/>
								}
								placeholder="E-mail"
								size="large"
								onChange={event => {
									const { value } = event.target;
									setState(state => {
										return {
											...state,
											email: value
										}
									});
								}}
							/>

						</Form.Item>


						<div className="options">
							<Button
								size={"large"}
								loading={loading}
								onClick={login}
								type="primary"
								htmlType="submit"
								className="login-form-button"
							>
								Continuar
							</Button>
						</div>




					</Form>
				</div>
			</div>
		</div>
	);
}

export default Login;
