import React, { useState, useEffect, useRef, Suspense } from "react";
import User from "store/user";
import {
	Layout,
	Avatar,
	Menu,
	Dropdown,
	Input,
	Icon,
	Form,
	Divider,
	AutoComplete,
	Button,
} from "antd";
import { Link, NavLink } from "react-router-dom";
import debounce from "functions/debounce";
import { useLazyQuery, useQuery } from "@apollo/client";
import * as Queries from "./Queries";
import { useHistory } from "react-router-dom";

import Balance from "./Balance";

import {
	UserOutlined,
	SearchOutlined,
	LoadingOutlined,
} from "@ant-design/icons";

const { Header } = Layout;
const { Option } = AutoComplete;

const ClientModal = React.lazy(() => import("scenes/clients/Modal"));
const ProductModal = React.lazy(() => import("scenes/products/Modal"));
const BillingReceiptModal = React.lazy(() =>
	import("scenes/billing-receipts/Modal")
);

function HeaderView(props) {
	const history = useHistory();

	const [search, { loading, data }] = useLazyQuery(Queries.SEARCH, {
		fetchPolicy: "no-cache",
	});

	useEffect(() => {
		window.logout = logout;
	}, []);

	const logout = () => {
		User.logout().then(() => {
			history.push("/login");
		});
	};

	return (
		<Header className="header-view">
			<div className="header-inset-view">
				<div className="user-info">
					<span className="user-name">
						{User.get("name")}
					</span>
					<div className="user-avatar">
						<UserOutlined
							style={{ fontSize: "20px", cursor: "pointer" }}
						/>
					</div>
					<Divider type="vertical" />
					{[1, 2].includes(User.info.type) ? (
						<Button
							type="link"
							onClick={() => {
								history.push("/profile");
							}}
						>
							Meu Perfil
						</Button>
					) : null}
					<Button type="default" onClick={logout}>
						Sair
					</Button>
				</div>
			</div>
		</Header>
	);
}

export default HeaderView;
