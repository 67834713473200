import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/client/react";
import "app-styles";
import { ConfigProvider } from "antd";

//
// Routes
//

import AppWrapper from "functions/appWrapper";
import PrivateRoute from "functions/privateRoute";
import Login from "scenes/login";
import Main from "scenes/main";
import UserInfo from "scenes/user-info";
import ResetPassword from "scenes/reset-password";
import NewPassword from "scenes/reset-password/new-password";
import Helmet from "react-helmet";

//
// Apollo
//

import Client from "functions/apollo";

//
// Locales
//

import ptBR from "antd/lib/locale/pt_BR";
import moment from "moment";
import "moment/locale/pt-br";

moment.locale("pt-br");

function App() {

  const {REACT_APP_PARTNER_LABEL, PUBLIC_URL, REACT_APP_THEME} = process.env;
  let favicon = `${PUBLIC_URL}/favicon.png`;
  if(REACT_APP_PARTNER_LABEL) favicon = `${PUBLIC_URL}/favicon-${REACT_APP_THEME}.png`;

	return (
		<>
      <Helmet>
        <title>{REACT_APP_PARTNER_LABEL || 'OpenMEI'}</title>
        <link rel="icon" href={favicon} />
      </Helmet>
			<ConfigProvider locale={ptBR}>
				<ApolloProvider client={Client}>
					<AppWrapper client={Client}>
						<Router>
							<Switch>
								<Route path="/login" component={Login} />
								<Route path="/reset-password" component={ResetPassword} />
								<Route path="/new-password/:token" component={NewPassword} />
								<Route
									path="/user-info"
									render={(props) => <UserInfo client={Client} {...props} />}
								/>
								<PrivateRoute path="/" component={Main} client={Client} />
							</Switch>
						</Router>
					</AppWrapper>
				</ApolloProvider>
			</ConfigProvider>
		</>
	);
}

export default App;
